<template>
    <div>
        <div class="inquire__wrapper">
            <div class="inquire__success">
                <zipcode-success></zipcode-success>
            </div>
            <div class="inquire__form">
                <div class="text-center">
                    <offer-progress-mobile-circular></offer-progress-mobile-circular>
                </div>
                <customer-referral-badge />
                <div class="inquire__instructions">
                    Nur noch wenige Angaben und wir können Ihr persönliches Angebot erstellen.
                </div>
                <form @submit.prevent="submit">
                    <div :class="{ 'form-field-error__container': errors.has('salutation') }">
                        <div class="form-field-error__text"
                             v-show="errors.has('salutation')">
                            {{ errors.first('salutation') }}
                        </div>
                        <div class="radio radio--inline">
                            <input type="radio"
                                   tabindex="0"
                                   id="herr"
                                   name="salutation"
                                   v-model="salutation"
                                   value="Herr"
                                   v-validate="'required'"
                                   data-vv-validate-on="input"
                                   v-focus-on-invalid>
                            <label for="herr">Herr</label>
                        </div>
                        <div class="radio radio--inline">
                            <input type="radio"
                                   id="frau"
                                   name="salutation"
                                   v-model="salutation"
                                   value="Frau"
                                   v-validate="'required'"
                                   data-vv-validate-on="input">
                            <label for="frau">Frau</label>
                        </div>
                    </div>
                    <div :class="{ 'form-field-error__container': errors.has('first_name') }">
                        <div class="form-field-error__text"
                             v-show="errors.has('first_name')">
                            {{ errors.first('first_name') }}
                        </div>
                        <div class="text-input">
                            <label for="first_name">Vorname</label>
                            <input type="text"
                                   name="first_name"
                                   v-validate="'required'"
                                   v-model.trim="first_name"
                                   id="first_name"
                                   :disabled="loading"
                                   v-focus-on-invalid>
                        </div>
                    </div>
                    <div :class="{ 'form-field-error__container': errors.has('last_name') }">
                        <div class="form-field-error__text"
                             v-show="errors.has('last_name')">
                            {{ errors.first('last_name') }}
                        </div>
                        <div class="text-input">
                            <label for="last_name">Nachname</label>
                            <input type="text"
                                   name="last_name"
                                   v-model.trim="last_name"
                                   v-validate="'required'"
                                   :disabled="loading"
                                   id="last_name"
                                   v-focus-on-invalid>
                        </div>
                    </div>
                    <div :class="{ 'form-field-error__container': errors.has('phone') }">
                        <div class="form-field-error__text"
                             v-show="errors.has('phone')">
                            {{ errors.first('phone') }}
                        </div>
                        <div class="text-input">
                            <label for="phone">Telefonnummer</label>
                            <input type="text"
                                   name="phone"
                                   v-model.trim="phone"
                                   v-validate="'required'"
                                   id="phone"
                                   inputmode="tel"
                                   :disabled="loading"
                                   v-focus-on-invalid>
                        </div>
                    </div>
                    <div :class="{ 'form-field-error__container': errors.has('email') }">
                        <div class="form-field-error__text"
                             v-show="errors.has('email')">
                            {{ errors.first('email') }}
                        </div>
                        <div class="text-input">
                            <label for="email">E-Mail</label>
                            <input type="text"
                                   name="email"
                                   v-model.trim="email"
                                   v-validate="'required|email|max:80'"
                                   id="email"
                                   inputmode="email"
                                   :disabled="loading"
                                   v-focus-on-invalid>
                        </div>
                    </div>
                    <div v-if="isInFebesolArea" :class="{
                        'form-field-error__container': errors.has('terms_privacy_febesol')
                        }">
                        <div class="form-field-error__text"
                             v-show="errors.has('terms_privacy_febesol')">
                            {{ errors.first('terms_privacy_febesol') }}
                        </div>
                        <div class="checkbox term">
                            <input type="checkbox"
                                   name="terms_privacy_febesol"
                                   id="terms_privacy_febesol"
                                   :disabled="loading"
                                   @click="saveTermItem($event)">
                            <label for="terms_privacy_febesol"
                                   id="terms_privacy_febesol_text">
                                   Ja, ich möchte über das telefonische Beratungsgespräch zu
                                   meiner konkreten Anfrage hinaus auch telefonische Informationen
                                   von der Thermondo GmbH und deren verbundenem Unternehmen,
                                   der FEBESOL GmbH zu weiteren Angeboten und Dienstleistungen,
                                   wie bspw. Photovoltaik erhalten.
                            </label>
                        </div>
                    </div>
                    <div :class="{ 'form-field-error__container': errors.has('terms_contact') }">
                        <div class="form-field-error__text"
                             v-show="errors.has('terms_contact')">
                            {{ errors.first('terms_contact') }}
                        </div>
                        <div class="checkbox term">
                            <input type="checkbox"
                                   name="terms_contact"
                                   id="terms_contact"
                                   :disabled="loading"
                                   @click="saveTermItem($event)">
                            <label for="terms_contact"
                                   id="terms_contact_text">
                                Ja, die Thermondo GmbH darf mir Tipps und Informationen rund um
                                Heizungswechsel und Photovoltaik zuschicken. Abmeldung ist jederzeit
                                möglich.
                            </label>
                        </div>
                    </div>
                    <ErrorInfoBox v-if="showErrorInfoBox" />
                </form>
                <div class="inquire--sm-none">
                    <ControlsButton></ControlsButton>
                </div>
                <div class="inquire__data-protection">
                    Auf Ihre Anfrage hin verarbeiten wir Ihre Angaben zum Zwecke der
                    Angebotserstellung. Weitere Informationen finden Sie in unserer
                    <a href="https://www.thermondo.de/intern/datenschutz/"
                        @click.prevent="showModalTerms()">Datenschutzerklärung</a>.
                </div>
            </div>
            <div class="inquire__visual-extras">
                <offer-visual></offer-visual>
                <offer-progress-desktop></offer-progress-desktop>
                <cost-saving-calculator v-if="renderCostSavingCalculator" />
                <todays-leads-counter v-if="renderTodaysLeadsCounter"></todays-leads-counter>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash';
import { mapState } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import navCommonMixin from '@/mixins/mixin.navCommon';
import behaviourModifiers from '@/mixins/mixin.behaviourModifiers';
import CostSavingCalculator from '@/components/CostSavingCalculator.vue';
import ZipcodeSuccess from '@/components/ZipcodeSuccess.vue';
import OfferVisual from '@/components/OfferVisual.vue';
import OfferProgressDesktop from '@/components/OfferProgressDesktop.vue';
import OfferProgressMobileCircular from '@/components/OfferProgressMobileCircular.vue';
import TodaysLeadsCounter from '@/components/TodaysLeadsCounter.vue';
import CustomerReferralBadge from '@/components/CustomerReferralBadge.vue';
import ErrorInfoBox from '@/components/ErrorInfoBox.vue';
import ControlsButton from '@/components/ControlsButton.vue';
import costSavings from '../config/cost-savings.json';
import febesolZipcodesList from '../config/febesol-zipcodes.json';

export default {
    inject: ['$validator'],
    mixins: [
        navCommonMixin,
        behaviourModifiers,
        ControlsButton,
    ],
    mounted() {
        this.applyBehaviourModifiers();
    },
    methods: {
        saveTermItem(event) {
            if (event.target.checked) {
                this.$store.dispatch('collectedData/customer/addTerm', {
                    name: event.target.name,
                    text: _.trim(
                        document.getElementById(`${event.target.id}_text`).innerHTML,
                    ),
                });
            } else {
                this.$store.dispatch(
                    'collectedData/customer/removeTerm',
                    event.target.name,
                );
            }
        },
        showModalTerms() {
            this.$store.dispatch('ui/modal/terms/show');
        },
    },
    computed: {
        ...mapState('ui', [
            'loading',
            'showErrorInfoBox',
        ]),
        ...mapFields('collectedData/customer', [
            'salutation',
            'first_name',
            'last_name',
            'phone',
            'email',
        ]),
        renderTodaysLeadsCounter() {
            return this.$store.state.requests.statistics.amountOfLeadsToday !== false;
        },
        renderCostSavingCalculator() {
            const {
                energy_source_old: sourceOld,
                energy_source_new: sourceNew,
                old_heating_energy_consumption: consumption,
            } = this.$store.state.collectedData.answers;

            return !!(
                sourceNew === 'air_to_water_heat_pump'
                && costSavings[sourceOld]
                && costSavings[sourceOld][consumption]
            );
        },
        isInFebesolArea() {
            const {
                pv_system_existing_or_planned: pvSystem,
                building_type: buildingType,
            } = this.$store.state.collectedData.answers;

            const { zipcode } = this.$store.state.collectedData.customer;
            // eslint-disable-next-line max-len
            const isZipcodeExist = febesolZipcodesList.zipcodesList.includes(parseInt(zipcode, 10));
            if (isZipcodeExist) {
                return (pvSystem === 'interested' || pvSystem === 'unsure') && buildingType !== 'apartment';
            }
            return false;
        },
    },
    components: {
        ControlsButton,
        ZipcodeSuccess,
        OfferVisual,
        OfferProgressDesktop,
        OfferProgressMobileCircular,
        TodaysLeadsCounter,
        CustomerReferralBadge,
        CostSavingCalculator,
        ErrorInfoBox,
    },
    directives: {
        focusOnInvalid: {
            inserted(el, binding, vnode) {
                vnode.context.$store.dispatch('ui/fields/focusAndScrollToOnError/register', {
                    name: el.name,
                    el,
                    data: {},
                });
            },
        },
    },
};
</script>
